import { $host } from '../../libs/user-api'
import _ from 'lodash'
export default {
  state: {
    monotoringObjects: [],
    backupCounter: null,
  },
  mutations: {
    updateMonotoringObjects(state, monotoringObjects) {
      state.monotoringObjects = monotoringObjects
    },
    updateMonotoringObjectsSocket(state, data) {
      const objIndex = state.monotoringObjects.findIndex((o) => o.bpId == data.bpId)
      if (objIndex == -1) state.monotoringObjects.push(data)
      else state.monotoringObjects.splice(objIndex, 1, data)
    },
    updateBackupCounter(state, data) {
      state.backupCounter = data
    },
    connectObj(state, data) {
      const objIndex = state.monotoringObjects.findIndex((o) => o.bpId == data.bpId)
      if (objIndex == -1) state.monotoringObjects.push(data)
      else state.monotoringObjects.splice(objIndex, 1, data)
    },
    // updateoOnlineObjects(state, onlineObjects) {
    //   state.onlineObjects = onlineObjects
    // },
  },
  actions: {
    // async getMonotoringObjects(ctx) {
    //   let citiesData = await $host.get(`/api/monitoringObjects`)
    //   ctx.commit('updateMonotoringObjects', citiesData.data)
    // },
    SOCKET_monitoring(ctx, data) {
      ctx.commit('updateMonotoringObjectsSocket', data)
    },
    SOCKET_backupCounter(ctx, data) {
      ctx.commit('updateBackupCounter', data)
    },
    SOCKET_connecto(ctx, data) {
      ctx.commit('connectObj', data)
    },
    async getMonotoringObjects(ctx) {
      let regionsData = await $host.get(`/api/sockets`)
      ctx.commit('updateMonotoringObjects', regionsData.data)
    },
    async rebootClients() {
      await $host.post('./api/rebootClients')
    },
  },
  getters: {
    monitoring(state) {
      let objectList = []
      for (const object of state.monotoringObjects) {
        objectList.push({
          title: object.title,
          id: object.bpId,
          socketId: object.online ? object.socketId : null,
          gitLatestCommit: object.online ? object.gitLatestCommit : null,
          state: object.online == 'true' ? 'подключен' : 'отключен',
          timeCheck: object?.monitoring ? object.monitoring.timeCheck : null,
          RAM: object?.monitoring ? `${(object.monitoring.RAM.used * 100).toFixed()}%` : null,
          HDD: object?.monitoring ? object.monitoring.HDD.map((i) => `${i.disk} ${i.freeSpace}`).join('\r\n') : null,
          CPU: object?.monitoring
            ? object.monitoring.CPU.map((i) => Math.round(i.load * 100) / 100).join('\r\n')
            : null,
          lines: object?.monitoring ? object.monitoring.lines : [],
          OS: object?.monitoring?.OS ? `${object.monitoring.OS.platform}${object.monitoring.OS.arch}` : null,
          // socket: online ? online.socket : null,
          // icon: socket ? 'mdi-lan-check' : 'mdi-lan-disconnect',
        })
      }
      return _.sortBy(objectList, 'state')
    },
    backupCounter(state) {
      return state.backupCounter
    },
  },
}
