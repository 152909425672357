import Vue from 'vue'
import VueRouter from 'vue-router'
// import store from '../store'

Vue.use(VueRouter)

const routes = [
  {
    path: '/login',
    name: 'login',
    meta: {
      auth: false,
      layout: 'Empty',
    },
    component: () => import('../views/Login'),
  },
  {
    path: '/set_password',
    name: 'set password',
    meta: {
      auth: false,
      layout: 'Empty',
    },
    component: () => import('../views/PasswordChange'),
  },
  {
    path: '/profile',
    name: 'profile',
    meta: {
      auth: true,
      layout: 'Navbar',
    },
    component: () => import('../views/Profile'),
  },
  {
    path: '/files',
    name: 'files',
    meta: {
      auth: false,
      layout: 'Navbar',
    },
    component: () => import('../components/FileExplorer'),
  },
  {
    path: '/monitoring',
    name: 'monitoring',
    meta: {
      auth: true,
      layout: 'Navbar',
    },
    component: () => import('../views/Monitoring'),
  },
  {
    path: '/phones',
    name: 'phones',
    meta: {
      auth: true,
      layout: 'Navbar',
    },
    component: () => import('../views/SentedCalls'),
  },
  {
    path: '/report',
    name: 'report',
    meta: {
      auth: true,
      layout: 'Navbar',
    },
    component: () => import('../views/Report'),
  },
  {
    path: '/',
    name: 'main',
    meta: {
      auth: true,
      layout: 'Empty',
    },
  },

  // {
  //   path: "/car/:carRoute",
  //   name: "car",
  //   meta: {
  //     auth: true,
  //     layout: 'empty',
  //   },
  //   component: () => import('../views/Car'),
  // },

  // {
  //   path: '*',
  //   name: '404',
  //   meta: {
  //     auth: true,
  //     layout: 'empty',
  //   },
  //   component: () => import('../views/404'),
  // },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    //to, from, savedPosition
    return { x: 0, y: 0 }
  },
})

// router.beforeEach((to, from, next) => {
//   const auth = store.getters.userAuth
//   const requireAuth = to.matched.some((record) => record.meta.auth)
//   console.log(requireAuth && !auth)
//   if (requireAuth && !auth) {
//     next('/login')
//   } else {
//     next()
//   }
// })

export default router
