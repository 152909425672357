<template>
  <nav>
    <v-app-bar app>
      <v-app-bar-nav-icon @click="drawer = !drawer"></v-app-bar-nav-icon>
      <v-toolbar-title class="text-uppercase grey--text">
        <span>Центральный сервер</span>
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn v-if="userAuth" @click="profileButtonClick">
        <v-icon>mdi-account</v-icon>
        <span> {{ user.title }}</span>
      </v-btn>
      <v-btn @click="logoutUser()">
        <span>{{ userAuth ? 'выйти' : 'войти' }}</span>
        <v-icon>mdi-exit-to-app</v-icon>
      </v-btn>
    </v-app-bar>
    <v-navigation-drawer app v-model="drawer">
      <v-list>
        <v-subheader>Меню</v-subheader>
        <v-list-item-group color="primary">
          <v-list-item v-for="(item, i) in listItems" :key="i" router :to="item.route" :disabled="item.disabled">
            <v-list-item-icon>
              <v-icon>{{ item.icon }}</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{ item.title }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>
  </nav>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
export default {
  data() {
    return {
      drawer: false,
      selectedItem: true,
      listItems: [
        {
          title: 'Мониторинг',
          icon: 'mdi-monitor-eye',
          route: '/monitoring',
          disabled: false,
        },
        {
          title: 'Телефоны',
          icon: 'mdi-phone-incoming',
          route: '/phones',
          disabled: false,
        },
        {
          title: 'Отчёт',
          icon: 'mdi-update',
          route: '/report',
          disabled: false,
        },
        //    {
        //        title: "Заявки",
        //        icon: "mdi-briefcase-variant",
        //        route: '/bookings',
        //        disabled: true
        //    },
        //  {
        //      title: "Календарь",
        //      icon: "mdi-update",
        //      route: '/calendar',
        //      disabled: false
        //  }
      ],
    }
  },
  methods: {
    profileButtonClick() {
      this.$router.push('/profile')
    },
    logout() {
      //  this.$router.push('/login')
    },
    ...mapActions(['logoutUser']),
  },
  computed: mapGetters(['user', 'userAuth']),
}
</script>

<style lang="scss" scoped>
</style>