import axios from 'axios'
// import router from '../router/index'

const $host = axios.create({
  baseURL: process.env.BASE_URL,
})

// const authInterceptor = (data) => {
//   try {
//     const url = data.config.url.toLowerCase().split("/")[1];
//     const isAuhtoraized = data.data.status;
//     if (url === "auth") return data;

//     if (isAuhtoraized === false) router.push("/login")
//      // || (isAuhtoraized === undefined && isAuhtoraized !== true)
//     //  || (data.config.method === 'get' && url === 'freecars')
//       else return data;

//   } catch (e) {
//     console.log("error with interceptor:", e);
//   }
// };

// $host.interceptors.response.use(authInterceptor)

export { $host }
