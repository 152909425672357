import moment from 'moment'
import { $host } from '../../libs/user-api'

export default {
  state: {
    sentedPhonesList: [],
  },
  mutations: {
    updatePhonesList(state, sentedPhonesList) {
      state.sentedPhonesList = sentedPhonesList
    },
  },
  actions: {
    async updatePhones(ctx, data = null) {
      let currentData = moment().format('YYYY-MM-DD')
      let result = await $host.get(`api/sendet_phones?date=${data || currentData}`)
      ctx.commit('updatePhonesList', result.data.data)
    },
  },
  getters: {
    sentedPhonesList(state) {
      return state.sentedPhonesList
    },
  },
}
