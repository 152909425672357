import { $host } from '../../libs/user-api'
import router from '../../router/index'

const getDefaultState = () => {
  return {
    id: '',
    title: '',
    phone: '',
    emails: '',
    auth: false,
    agreed: false,
  }
}
const state = getDefaultState()

export default {
  state,
  mutations: {
    updateAuth(state, auth) {
      state.auth = auth
    },
    updateErrorMessage(state, message) {
      state.errorMessage = message
    },
    updateUser(state, { emails, id, phone, title }) {
      state.emails = emails
      state.id = id
      ;(state.phone = phone), // .replace(/[+\(\)-]/g,'');
        (state.title = title)
    },
    resetState(state) {
      Object.assign(state, getDefaultState())
    },
    updateAgreement(state, value) {
      state.agreed = value
    },
  },
  actions: {
    async restorePassword(ctx, phone) {
      try {
        let restore = await $host.post('/auth/restore_password', { phone })
        return restore
      } catch (e) {
        console.log(e)
      }
    },
    async getUser(ctx) {
      const { data } = await $host.get('/auth/login')
      ctx.commit('updateAuth', data.status)
      if (data.status) {
        ctx.commit('updateUser', data.user)
        router.push('/monitoring')
      } else router.push('/login')
      return data
    },
    async setNewPassword(ctx, password) {
      try {
        let setPass = await $host.post('/auth/set_password', password)
        return setPass
      } catch (e) {
        console.log(e)
      }
    },
    async logoutUser(ctx) {
      try {
        await $host.post('auth/logout')
        ctx.commit('resetState')
        router.push('/login')
      } catch (e) {
        console.log(e)
      }
    },
    async loginUser(ctx, obj) {
      try {
        ctx.commit('updateErrorMessage', '')
        const { phone, password } = obj
        let logging = await $host.post('/auth/login', { phone, password }).catch((e) => {
          ctx.commit('updateErrorMessage', 'Неверный логин и/или пароль')
          console.log('!Error!', e)
        })

        if (logging?.data.status) {
          const auth = logging.data.status

          ctx.commit('updateUser', logging.data.user)

          ctx.commit('updateAuth', auth)
          router.push('/monitoring')
        } else {
          console.log('try again')
          ctx.commit('updateErrorMessage', 'Неверный логин и/или пароль')
        }
      } catch (e) {
        console.log(e)
      }
    },
  },

  getters: {
    user(state) {
      return state
    },
    userAuth(state) {
      return state.auth
    },
  },
}
