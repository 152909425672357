import { $host } from '../../libs/user-api'

export default {
  state: {
    bpId: null,
    title: null,
    loading: false,
    files: [],
    folderPath: '',
  },
  mutations: {
    updateLoading(state) {
      state.loading = !state.loading
    },
    updateData(state, data) {
      state.files = data.files
      state.folderPath = data.folderPath
    },
    updateFolderPath(state, path) {
      state.folderPath = path
    },
    updateFilesObject(state, { bpId, title }) {
      state.bpId = bpId
      state.title = title
    },
  },
  actions: {
    async getFiles(ctx) {
      ctx.commit('updateLoading')
      let { data } = await $host.get('/api/clientReadDir', {
        params: { folderPath: ctx.state.folderPath, bpId: ctx.state.bpId },
      })
      ctx.commit('updateLoading')
      ctx.commit('updateData', data)
    },
    async up(ctx) {
      let folderPathChunks = ctx.state.folderPath.split(':')
      let drive = folderPathChunks[0]
      let path = folderPathChunks[1]
      if (ctx.state.folderPath == '\\') return
      if (path.length > 1) {
        let pathArr = path.split('\\')
        pathArr.pop()
        path = pathArr.join('\\')
        ctx.commit('updateFolderPath', `${drive}:${path ? path : '\\'}`)
      } else ctx.commit('updateFolderPath', '\\')
      await ctx.dispatch('getFiles')
    },
    async openDir(ctx, name) {
      if (ctx.state.folderPath != '\\') {
        let folderPathArr = ctx.state.folderPath.split('\\')
        folderPathArr.push(name)
        ctx.commit('updateFolderPath', folderPathArr.join('\\'))
      } else {
        ctx.commit('updateFolderPath', `${name}${ctx.state.folderPath}`)
      }
      await ctx.dispatch('getFiles')
    },
  },

  getters: {
    fileExplorer(state) {
      return state
    },
  },
}
