import Vue from 'vue'
import Vuex from 'vuex'
import user from './modules/user'
import monitoring from './modules/monitoring'
import phones from './modules/sentedPhones'
import fileExplorer from './modules/fileExplorer'
import report from './modules/report'

//import createPersistedState from 'vuex-persistedstate'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    title: 'Центральный сервер',
  },
  mutations: {},
  actions: {},
  //  plugins: [createPersistedState()],
  modules: {
    user,
    monitoring,
    fileExplorer,
    phones,
    report,
  },
  getters: {},
})
