import { $host } from '../../libs/user-api'
import _ from 'lodash'
import moment from 'moment'

export default {
  state: {
    lines: [],
    LPUList: [],
  },
  mutations: {
    updateReport(state, lines) {
      state.lines = lines
    },
    updateLPUList(state, sockets) {
      state.LPUList = sockets.map((s) => ({
        bpId: s.bpId,
        title: s.title,
        online: s.online,
      }))
    },
    clearLines(state) {
      state.lines = []
    },
  },
  actions: {
    async getSockets(ctx) {
      let resposne = await $host.get(`/api/sockets`)
      ctx.commit('updateLPUList', resposne.data)
    },

    async getReports(ctx, { bpId, date, view }) {
      let response = await $host.get(
        `/api/report?view=${view}&bpId=${bpId}&date=${moment(date, 'YYYY-MM').format('YYYY-MM-DD')}`,
      )
      ctx.commit('updateReport', response.data)
    },
  },
  getters: {
    getStatistics(state) {
      return _.sortBy(state.lines, 'state')
    },
    LPUList(state) {
      return state.LPUList.filter((l) => l.online == 'true')
    },
  },
}
