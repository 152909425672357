<template>
  <v-app class="gray lighten-4">
    <component :is="layout" />
    <v-main class="mx-4 mb-4">
      <router-view></router-view>
    </v-main>
  </v-app>
</template>
<script>
import Navbar from '@/layouts/Navbar'
import Empty from '@/layouts/Empty'
// import { mapActions } from "vuex";

export default {
  name: 'App',
  metaInfo: {
    title: 'Сервер статистики',
  },
  components: {
    Navbar,
    Empty,
  },
  data: () => ({
    //
  }),
  methods: {
    // ...mapActions(["getUser"]),
  },
  computed: {
    layout() {
      return this.$route.meta.layout || 'Empty'
    },
  },
  async created() {
    // await this.getUser();
  },
}
</script>
